import { DateTime, DateTimeUnit, DurationLike } from "luxon";
import {
  AdminRole,
  AlertParameter,
  AlertParameterKey,
  ApisDevice,
  ApisDeviceT, ApisDeviceTWithConfig,
  ApisDeviceWithConfig,
  CalculatedSample,
  DeviceConfigType, DeviceConfigValue,
  DeviceFeature,
  DeviceSample,
  DeviceType,
  FlareMonitor, GuardRoleDefinitionT,
  HeaderController,
  HeaderMonitor,
  HistoricalDeviceConfigValue,
  OutlierCalculations,
  SmartWell, UserRole, UserRoleDefinitionT, UserScope,
  WellMonitor
} from "@apis/types";
import { quantileSeq } from "mathjs";
import { ADMIN_ROLES, DEVICE_CONFIG_DETAILS, DEVICE_CONFIG_TYPE, DEVICE_TYPE, DEVICE_TYPE_DETAILS, ROLE_SCOPES } from "@apis/constants";

export const getAverageRangeDuration = (start: string, end: string, hourlyAllowed = false): DateTimeUnit => {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  const { weeks } = endDate.diff(startDate, ["weeks"]).toObject();
  if (weeks && weeks >= 6 * 4) return "week";
  else if (weeks && weeks >= 4) return "day";
  else return hourlyAllowed ? "hour" : "day";
}

export const getFixedValue = (value: any, decimal = 2) => {
  if(value === undefined || isNaN(value)) {
    return undefined
  }
  return Math.floor(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

const getRDValue = (plateSize: number) => {
  if(plateSize > 1.4) {
    return 35182.41;
  } else if(plateSize > 1.25) {
    return 26786.85;
  } else if(plateSize > 1) {
    return 8323.394;
  } else {
    return 2912.891;
  }
};

const getMolecularWeight = (CH4: number, CO2: number, O2: number) => {
  const bal = 100 - CH4 - CO2 - O2;
  return ((CH4 * 16) + (CO2 * 44) + (O2 * 32) + (bal * 28)) / 100;
}

const getFluidDensity = (gasTempF: number, staticPressure: number, molecularWeight: number, barometricPressure: number) => {
  const IDEAL_GAS_CONSTANT = 8.3137
  const TK = ((gasTempF - 32) * 5/9) + 273.15;
  const staticPressureAbs = Math.abs(staticPressure * 248.84);
  const PA = ((barometricPressure * 100) < staticPressureAbs ? 1013.25 : barometricPressure) * 100 - staticPressureAbs;
  return (((molecularWeight / 1000) * PA) / (IDEAL_GAS_CONSTANT * TK)) * 0.062428;
}

const calculateVenturiFlow = (device: SmartWell | WellMonitor, entry: DeviceSample) => {
  const isSCFM = device.landfill.flowCalculation === "SCFM";
  const METER_COEFFICIENT = 0.96
  const gasTemp = isSCFM ? 68 : (entry.gasTemp || 95);
  const pipeDiameter = device.pipeDiameter / 12;
  const restrictionSize = getRestrictionSize(entry.date, device.restrictionSize) / 12;
  const CONSTRICTED_AREA = Math.PI * Math.pow(restrictionSize / 2, 2);
  const DIAMETER_RATIO = restrictionSize / pipeDiameter;
  const diffPressure = Math.abs(entry.diffPressure) || 0;
  const staticPressure = isSCFM ? 0 : (device.secondaryStaticPressurePort ? (entry.staticPressure || 0) - diffPressure : entry.staticPressure || 0);
  const barometricPressure = isSCFM ? 1013.25 : (entry.barEnc || 1013.25);
  const PRESSURE = diffPressure * 0.0360912;
  const MEASURED_PRESSURE = PRESSURE * 144
  const molecularWeight = isSCFM ? getMolecularWeight(0, 0, 20.9) : getMolecularWeight(entry.CH4, entry.CO2, entry.O2)
  const DENSITY = getFluidDensity(gasTemp, staticPressure, molecularWeight, barometricPressure) * 0.031081;
  const PIPE_FLOW_RATE = METER_COEFFICIENT * CONSTRICTED_AREA * Math.sqrt(2 * MEASURED_PRESSURE / (DENSITY * (1 - Math.pow(DIAMETER_RATIO, 4))))
  return PIPE_FLOW_RATE * 60;
}

const calculateOPlateFlow = (device: SmartWell | WellMonitor, entry: DeviceSample) => {
  const isSCFM = device.landfill.flowCalculation === "SCFM";
  const timestamp = entry.date;
  const pipeDiameter = device.pipeDiameter;
  const diffPressure = entry.diffPressure || 0;
  const staticPressure = isSCFM ? 0 : (device.secondaryStaticPressurePort ? (entry.staticPressure || 0) - diffPressure : entry.staticPressure || 0);
  const barometricPressure = isSCFM ? 1013.25 : (entry.barEnc || 1013.25);
  const gasTemp = isSCFM ? 68 : (entry.gasTemp || 95);
  const restrictionSize = getRestrictionSize(timestamp, device.restrictionSize);
  const BETA = restrictionSize / pipeDiameter;
  const C2 = 0.6430;
  const molecularWeight = isSCFM ? getMolecularWeight(0, 0, 20.9) : getMolecularWeight(entry.CH4, entry.CO2, entry.O2)
  const DENSITY = getFluidDensity(gasTemp, staticPressure, molecularWeight, barometricPressure);
  const QM = 0.0997019 * C2 * Math.pow(restrictionSize, 2) * Math.sqrt((diffPressure * DENSITY) / (1 - Math.pow(BETA, 4)));
  return ((QM / DENSITY) * 60) || 0;
};

const calculatePitotFlow = (device: HeaderMonitor | HeaderController | FlareMonitor, entry: DeviceSample) => {
  const isSCFM = device.landfill.flowCalculation === "SCFM";
  const pipeDiameter = device.pipeDiameter;
  const diffPressure = entry.diffPressure || 0;
  const staticPressure = isSCFM ? 0 : (device.secondaryStaticPressurePort ? (entry.staticPressure || 0) - diffPressure : entry.staticPressure || 0);
  const gasTemp = isSCFM ? 68 : (entry.gasTemp || 95);
  const centerVelocityEstimateFactor = device.centerVelocityEstimateFactor || 0.8;
  const Pa = 29.92 + (0.07348 * staticPressure);
  const Ta = 460 + (gasTemp);
  const DensityAir = 0.0750;
  const DensityLFG = getMolecularWeight(entry.CH4, entry.CO2, entry.O2) / (0.73 * Ta);
  const Gair = DensityLFG / DensityAir;
  const A = Math.PI * Math.pow(pipeDiameter / 12, 2) / 4;
  const V = centerVelocityEstimateFactor * 0.85 * 60 * 2.9 * (Math.sqrt((29.92 / Pa) * Gair * Ta * diffPressure) || 0);
  const Qacfm = V * A;
  const Qscfm = Qacfm * ( Pa / 29.92 ) * ( 520 / Ta )
  return isSCFM ? Qscfm : Qacfm;
}

export const calculateFlow = (dvc: ApisDevice, sample: DeviceSample) => {
  if (dvc.deviceType === DEVICE_TYPE.SMART_WELL || dvc.deviceType === DEVICE_TYPE.WELL_MONITOR) {
    const device = dvc as SmartWell | WellMonitor
    if (device.flowMeter === "Orifice Plate") {
      return calculateOPlateFlow(device, sample);
    }else {
      return calculateVenturiFlow(device, sample);
    }
  } else if (dvc.deviceType === DEVICE_TYPE.HEADER_CONTROLLER || dvc.deviceType === DEVICE_TYPE.FLARE_MONITOR || dvc.deviceType === DEVICE_TYPE.HEADER_MONITOR) {
    return calculatePitotFlow(dvc as HeaderMonitor | HeaderController | FlareMonitor, sample);
  }
  return null;
};

export const calculateEnergyFlow = (sample: DeviceSample, flow: number) => ((sample.CH4 / 100 ) * flow) * 60 * 0.001;

export const getRestrictionSize = (timestamp: string, list: HistoricalDeviceConfigValue<number>) => {
  for(const plateSize of list){
    if(plateSize.createdAt <= timestamp) {
      return plateSize.value;
    }
  }
  return list[list.length - 1].value;
};

export const calculateSample = (sample: DeviceSample, device: ApisDevice, totalConnectorAverages: Record<string, number>): CalculatedSample => {
  const timestamp = DateTime.fromISO(sample.date, { zone: "utc" });
  const dpFlow = calculateFlow(device, sample);
  const flow = totalConnectorAverages ? totalConnectorAverages[timestamp.startOf("hour").toISO() as string] || dpFlow : dpFlow;
  const methaneFlow = sample.CH4 * flow / 100;
  const normalizedFlow = sample.CH4 * flow / 50;
  const energyFlow = calculateEnergyFlow(sample, flow);
  const bal = parseFloat((100 - sample.CH4 - sample.CO2 - sample.O2).toFixed(2));
  const staticPressure = (device as ApisDeviceWithConfig<"secondaryStaticPressurePort">).secondaryStaticPressurePort ? sample.staticPressure - sample.diffPressure : sample.staticPressure
  return { ...sample, date: timestamp, dpFlow, flow, methaneFlow, energyFlow, bal, staticPressure, normalizedFlow };
};

export const isParameterAlerted = (parameter: AlertParameterKey, value: number, parameters: AlertParameter[]) => {
  const alertParam = parameters.find(param => param.parameter === parameter);
  if (!alertParam) return false;
  return value > alertParam.max || value < alertParam.min;
}

export const getIntervalFromCron = (cron: string): number => {
  const match = cron.match(/0\s\*\/([0-9]{1,2})\s\*\s\*\s\*|\*\/([0-9]{1,2})\s\*\s\*\s\*\s\*/)
  return match ? parseInt(match[1] || match[2]) : NaN
}

export const getOutlierCalculations = (data: number[]): OutlierCalculations => {
  const [Q1, Q3] = quantileSeq(data, [0.25, 0.75]) as Array<number>;
  const IQR = Q3 - Q1;
  return { Q1, Q3, IQR, lowerBound: Q1 - (1.5 * IQR), upperBound: Q3 + (1.5 * IQR) };
}

export const celsiusToFahrenheit = (n: number) => (n * 1.8) + 32;

// To convert m/s to ft/s
export const msToFts = (n: number) => n * 3.28;

export const hpaToInhg = (n: number) => n * 0.02953;

export const mmToInch = (n: number) => n * 0.0394

export const getDeviceTypesByConfig = (name: DeviceConfigType): DeviceType[] => {
  return Object.entries(DEVICE_TYPE_DETAILS).reduce((acc, [key, value]) => {
    if (value.configs.includes(name)) acc.push(key)
    return acc;
  }, [])
}

export const getDeviceTypesByFeature = (name: DeviceFeature): DeviceType[] => {
  return Object.entries(DEVICE_TYPE_DETAILS).reduce((acc, [key, value]) => {
    if (value.features.includes(name)) acc.push(key)
    return acc;
  }, [])
}

export const deviceHasConfig = (type: DeviceType, config: DeviceConfigType) => DEVICE_TYPE_DETAILS[type].configs.includes(config)
export const deviceHasFeature = (type: DeviceType, feature: DeviceFeature) => DEVICE_TYPE_DETAILS[type].features.includes(feature)


export const getConfigValueExtendDefault = (device: ApisDeviceT, type: DeviceConfigType) => {
  const value: DeviceConfigValue | void = (device as ApisDeviceTWithConfig<typeof type>)[type]
  if (value !== undefined && value !== null) return value;
  return DEVICE_CONFIG_DETAILS[type].defaultValue;
}

export const getScopesByRole =(role: UserRole): UserScope[] => ROLE_SCOPES[role];
export const roleCanAccess = (role: UserRole, scope: UserScope) => ROLE_SCOPES[role].includes(scope)

export const generateRandomPassword = (): string => `.!AXY${Math.random().toString(36).slice(-8)}123`

export const canUserAccessResource = (userRoles: GuardRoleDefinitionT[], scope: UserScope, resources?: { companyID?: string, landfillID?: string }) => {
  const roles = getRoleListFromScope(scope)
  return userRoles.some(role => {
    const roleDef = role as UserRoleDefinitionT;
    if (ADMIN_ROLES.includes(roleDef.role as AdminRole)) {
      if (roles.includes(roleDef.role)) return true;
    } else if (roles.includes(roleDef.role)) {
      if (!resources || (roleDef.company && roleDef.company === resources?.companyID) || (roleDef.landfill && roleDef.landfill === resources?.landfillID)) return true
    }
    return false
  })
}

const getRoleListFromScope = (scope: UserScope): UserRole[] => {
  return Object.entries(ROLE_SCOPES).filter(([_, scopes]) => scopes.includes(scope)).map(([role]) => role as UserRole)
}
