import { MantineProvider } from "@mantine/core";
import { RouterProvider } from "react-router-dom";
import { router } from "../pages";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { useUserMetadata } from "../hooks";
import { useJsApiLoader } from "@react-google-maps/api";
import { environment } from "../environments/environment";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
const libraries: Libraries = ["visualization"];

export function App() {
  const { preferences } = useUserMetadata();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: environment.GOOGLE_MAPS_API_KEY,
    libraries
  })

  return (
    <MantineProvider theme={{
      colorScheme: preferences.theme,
      breakpoints: {
        lg: "80rem",
        xl: "100rem"
      },
      components: {
        Select: {
          defaultProps: {
            withinPortal: true
          }
        },
        Tooltip: {
          defaultProps: {
            events: { hover: true, focus: true, touch: true }
          }
        }
      }
    }} withGlobalStyles withNormalizeCSS>
      <ModalsProvider>
        <Notifications />
        { isLoaded && <RouterProvider router={router} /> }
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
