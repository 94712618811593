import { AsyncOperationStatus } from "@apis/types";

export const ASYNC_OPERATION_TYPE = {
  SITE_SUMMARY_REPORT: "SITE_SUMMARY_REPORT",
  CALIBRATION_SUMMARY_REPORT: "CALIBRATION_SUMMARY_REPORT",
  LANDFILL_UPTIME_REPORT: "LANDFILL_UPTIME_REPORT",
  CUSTOMER_DATA_IMPORT: "CUSTOMER_DATA_IMPORT"
} as const
export const ASYNC_OPERATION_TYPES = [
  ASYNC_OPERATION_TYPE.SITE_SUMMARY_REPORT,
  ASYNC_OPERATION_TYPE.CALIBRATION_SUMMARY_REPORT,
  ASYNC_OPERATION_TYPE.LANDFILL_UPTIME_REPORT,
  ASYNC_OPERATION_TYPE.CUSTOMER_DATA_IMPORT
] as const
export const ASYNC_OPERATION_REPORT_TYPES = [
  ASYNC_OPERATION_TYPE.SITE_SUMMARY_REPORT,
  ASYNC_OPERATION_TYPE.CALIBRATION_SUMMARY_REPORT,
  ASYNC_OPERATION_TYPE.LANDFILL_UPTIME_REPORT
]
export const ASYNC_OPERATION_TYPE_CONFIG = {
  SITE_SUMMARY_REPORT: { desc: "Site Summary Report", recreatable: false },
  CALIBRATION_SUMMARY_REPORT: { desc: "Calibration Summary Report", recreatable: false },
  LANDFILL_UPTIME_REPORT: { desc: "Landfill Uptime Report", recreatable: true }
} as const


export const ASYNC_OPERATION_STATUS = {
  IDLE: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  FAILED: 3,
} as const

export const ASYNC_OPERATION_STATUS_LABELS = Object.fromEntries(Object.entries(ASYNC_OPERATION_STATUS).map(([key, value]) => [value, key])) as { [key in AsyncOperationStatus]: string };

export const ASYNC_OPERATION_STATUSES = [
  ASYNC_OPERATION_STATUS.IDLE,
  ASYNC_OPERATION_STATUS.IN_PROGRESS,
  ASYNC_OPERATION_STATUS.COMPLETED,
  ASYNC_OPERATION_STATUS.FAILED,
] as const
